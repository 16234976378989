import React from "react";
import { SmoothScrollButton } from "../../FormCommon/SmoothScroll";

class Final extends React.Component {
    render() {
        return (
            <section className="bg-light">
                <div className="container pt-6">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <h4>Looking for more?</h4>
                            <p className="lead mb-5 mb-lg-0 text-gray-500">Get in touch today.</p>
                        </div>
                        <div className="col-lg-6 text-lg-right">
                            <SmoothScrollButton
                            to="contact-us"
                            className="btn btn-primary btn-marketing mr-3 my-2 lift lift-sm"
                            content="Contact Us"
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Final;
