import { faAward, faBusinessTime, faCarAlt, faDollarSign, faLocationArrow, faPhone, faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "../Home.css"

class ValueProposition extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="section text-center object-wrap vertical-center" id="value">
          <div className="row justify-content section-xl" style={{ minHeight: "200px" }}>
            <div className="col-lg-4 bg-light p-5">
              <div className="container container-fluid">
                <h2><strong><FontAwesomeIcon icon={faCarAlt} /> All Car Makes</strong></h2>
                <p className="">We service all types of vehicles, ranging from everyday to premium and luxury. We also offer logbook servicing, that maintains your car's warranty.</p>
              </div>
            </div>
            <div className="col-lg-4 bg-primary p-5">
              <div className="container container-fluid text-white">
                <h2><strong><FontAwesomeIcon icon={faDollarSign} /> Value for Money</strong></h2>
                <p className="">We source all our high quality parts and materials at wholesale prices - saving you hard earned money, without compromise. We simply won't be beaten on price.</p>
              </div>
            </div>
            <div className="col-lg-4 bg-dark p-5">
              <div className="container container-fluid text-white">
                <h2><strong><FontAwesomeIcon icon={faAward} /> Quality, Guaranteed</strong></h2>
                <p className="">We offer warranties and a lifetime guarantee on select services. With qualified, expert mechanics, we stand proudly behind all our work.</p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ValueProposition;
