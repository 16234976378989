import React from 'react';

import './NotFoundPage.css';

interface Props {
}

const NotFoundPage: React.FC<any> = (props: Props) => {
    return (
        <div className="img-header-4">
            <div className="container h-full-page">
                <div className="py-10">
                    <h1 className="text-dark mb-2">Page Not Found</h1>
                    <h5 className="text-dark">HTTP 404</h5>
                    <p className="text-dark">Please check your URL and try again.</p>
                </div>
            </div>
        </div>
    );
}

export default NotFoundPage;

