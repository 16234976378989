import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { IApplicationState } from '../../store';
import { connect } from 'react-redux';
import { ApplicationUrls } from '../../constants/ApplicationUrls';
import { DarkModeUtils } from '../../utils/DarkModeUtils';
import { SmoothScroll } from '../FormCommon/SmoothScroll';

import './NavMenu.css';

import logo from '../../assets/img/brand/brand.png'
import logoWhite from '../../assets/img/brand/brand_white.png'

interface Props {
}

interface State {
    isOpen: boolean;
    isDarkMode: boolean;
}

class NavMenu extends React.Component<Props, State> {

    public state = {
        isOpen: false,
        isDarkMode: false
    };

    componentWillMount() {
        const isDarkMode = DarkModeUtils.isDarkModePreferred();
        this.setState({ isDarkMode: isDarkMode });
        DarkModeUtils.setDarkModePreference(isDarkMode);
    }
    
    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    private toggleIfOpen = () => {
        if (this.state.isOpen === true) {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }

    private toggleDarkMode = () => {
        return;
        const didSet = DarkModeUtils.setDarkModePreference(!this.state.isDarkMode);
        if (didSet) {
            this.setState({ isDarkMode: !this.state.isDarkMode });
        }
    }

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow" light>
                    <Container>
                        <NavbarBrand tag={Link} to={ApplicationUrls.Home}>
                            <img src={logoWhite} width="70%" className="float-left" alt="EagleAutomotive logo"/>
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse menu text-white" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                <React.Fragment>
                                    <NavItem onClick={this.toggleIfOpen}>
                                        <SmoothScroll className="text-white nav-link" to="value" content="About" />
                                    </NavItem>
                                    <NavItem onClick={this.toggleIfOpen}>
                                        <SmoothScroll className="text-white nav-link" to="contact-us" content="Contact Us" />
                                    </NavItem>
                                </React.Fragment>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    ...state,
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>) => ({
});

export default
    withRouter(connect(
        mapStateToProps,
        mapDispatchToProps)
        (NavMenu));