import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faAddressCard, faClock, faEnvelopeSquare, faMapMarkerAlt, faPhone, faSearch, faSearchDollar, faUserClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { MessageRequest } from "../../../store/messages/types";
import ContactUsForm from "./ContactUsForm";
import GoogleMaps from "./GoogleMaps";

export const ContactUs: React.FC<{ onSubmit(messageRequest: MessageRequest): void }> = (props: any) => {
    const [showContactNumber, setShowContactNumber] = useState(false);

    const displayContactNumber = () => {
        setShowContactNumber(true);
    }

    return (
        <React.Fragment>
            <section className="bg-dark py-0" id="contact-us">
                <div className="py-0">
                    <div className="row">
                        <div className="col-lg-6 text-center order-2 order-lg-1" data-aos="fade-up">
                            {/* <ContactUsForm onSubmit={props.onSubmit} /> */}
                            <GoogleMaps />
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 justify-content-center container">
                            <div className="pt-8 py-8 px-5">
                                <h1 className="text-white">Find us today</h1>
                                {/* <h3 className="text-white pt-5">We look forward to your company.</h3> */}
                                <p className="lead text-white pt-5">
                                    <FontAwesomeIcon icon={faPhone} />&nbsp;|
                                    {showContactNumber ?
                                        <a className="text-white" href="tel:+61861188446">&nbsp;&nbsp;(08) 6118 8446</a> :
                                        <a href="javascript:void(0);" onClick={displayContactNumber} className="text-white">&nbsp;(08) 6118 8XXX<small>(click to reveal)</small></a>
                                    }
                                </p>
                                <p className="lead text-white">
                                    <a href="https://goo.gl/maps/UPjiVwwSs8UzfuvZ6" className="text-white">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;|
                                        Unit 2/35 Colin Jamieson Drive, Welshpool WA, 6106
                                    </a>
                                </p>
                                <p className="lead text-white">
                                    <FontAwesomeIcon icon={faClock} />&nbsp;|
                                    Monday to Friday, 7am to 4pm
                                </p>
                                <div style={{ fontSize: "3rem" }}>
                                    <a className="icon-list-social-link text-white large" href="https://www.facebook.com/devizorau/">
                                        <FontAwesomeIcon icon={faFacebookSquare} />
                                    </a>
                                    <a className="icon-list-social-link text-white pl-3" href="mailto:enquire@eagleautomotive.com.au">
                                        <FontAwesomeIcon icon={faEnvelopeSquare} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default ContactUs;
