export class Logger {

    static shouldLog: boolean = true;

    static logInfo(message: any): void {
        if(this.shouldLog){
            console.log(message);
        }
    }

    static logError(message: any): void {
        if(this.shouldLog){
            console.error(message);
        }
    }
}