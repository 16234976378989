import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MarkdownText from '../FormCommon/MarkdownText';

import './LegalPage.css';

const LegalPage = () => (
    <React.Fragment>
        <div className="container">
            <div className="pt-5">
                <MarkdownText filePath="documents/legal.md" />
            </div>
        </div>
    </React.Fragment>
);

export default withRouter(connect()(LegalPage));