import React from 'react';
import { scroller } from 'react-scroll';
import { ButtonGroup, Input, Button, FormFeedback } from "reactstrap"

export interface SmoothScrollButtonProps {
    to: string,
    content: string,
    onClick?: any,
    className?: string
}

export const SmoothScroll: React.FC<any> = (props: SmoothScrollButtonProps) => {

    const handleOnClick = () => {

        if (props.onClick) {
            props.onClick();
        }

        setTimeout(function () {
            scroller.scrollTo(props.to, {
                smooth: true,
                duration: 500
            });
        }, 0);
    }

    return (
        <div>
            <a className={props.className} onClick={handleOnClick}>{props.content}</a>
        </div>
    )
}

export const SmoothScrollButton: React.FC<any> = (props: SmoothScrollButtonProps) => {

    const handleOnClick = () => {
        if(props.onClick) props.onClick();
        setTimeout(function () {
            scroller.scrollTo(props.to, {
                smooth: true,
                duration: 500
            });
        }, 0);
    }

    return (
        <Button color="primary" onClick={handleOnClick} className={props.className}>{props.content}</Button>
    )
}

export interface ScrollOnAnswerButtonGroupProps {
    to: string;
    onClick: any;
    fieldName: string;
    innerRef: any;
    options: ScrollOnAnswerButtonGroupOption[];
    error: any;
    errorMessage: string;
}

export interface ScrollOnAnswerButtonGroupOption {
    value: string;
    text: string;
    onClick?: any;
}

export const ScrollOnAnswerButtonGroup: React.FC<any> = (props: ScrollOnAnswerButtonGroupProps) => {
    return (
        <div className="">
            <ButtonGroup className="flex-wrap">
                {
                    props.options.map(option => {
                        return <SmoothScroll to={props.to} content=
                            {
                                <Button color="primary" onClick={(evt) => {
                                    if(option.onClick) option.onClick();
                                    props.onClick(evt);
                                }} value={option.value}>{option.text}</Button>
                            } />
                    })
                }
                <Input type="hidden" name={props.fieldName} innerRef={props.innerRef} invalid={props.error}></Input>
                <FormFeedback>{props.errorMessage}</FormFeedback>
            </ButtonGroup>
        </div>
    )
}
