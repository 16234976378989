import * as React from 'react';
import Hero from "./partials/Hero";
import About from "./partials/About";
import ValueProposition from "./partials/ValueProposition";
import Final from "./partials/Final";
import Testimony from "./partials/Testimony";
import ContactUs from './partials/ContactUs';
import Results from './partials/Results';
import ServiceGuarantee from './partials/ServiceGuarantee';
import CallToAction from './partials/CallToAction';
import { MessageRequest } from '../../store/messages/types';

import './Home.css';

interface Props {
  sendMessage(request: MessageRequest): void;
}

class Home extends React.Component<Props, {}> {
  render() {
    return (
      <React.Fragment>
        <Hero />
        <ValueProposition />
        <About />
        <Results />
        <ServiceGuarantee />
        <CallToAction />
        <ContactUs onSubmit={this.props.sendMessage} />
        <Testimony />
        <Final />
      </React.Fragment>
    );
  }
}

export default Home;
