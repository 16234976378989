import React from "react";

import "../Home.css"

class ServiceGuarantee extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-primary text-center object-wrap vertical-center" id="results" style={{ minHeight: "400px" }}>
          <div className="bg-services object-body object-body-left"></div>
          <div className="container container-fluid">
            <div className="row justify-content-end section-xl text-white">
              <div className="col-lg-6">
                <h2><strong>Experts</strong> of the trade</h2>
                <p className="">We can solve almost any problem that occurs with your vehicle. From engine repairs and oil changes to regular maintenance and diagnostics. You will always get reliable services from our certified mechanics who use the latest in automotive equipment and diagnostic software.</p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ServiceGuarantee;
