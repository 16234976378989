import { MessageRequestTypeName, MessageActionTypes, MessageRequest } from "./types";
import { apiClient } from "../../constants";
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IApplicationState } from "..";
import {Logger} from "../../utils/Logger";

export const sendMessage: ActionCreator<ThunkAction<Promise<any>, IApplicationState, null, MessageActionTypes>> = (request: MessageRequest) => {
    return async (dispatch: Dispatch) => {
        try {
            const httpResponse = await apiClient.post('/messages', request);
            dispatch({
                type: MessageRequestTypeName.SendMessage,
                payload: httpResponse.data
            })
        } catch (err) {
            Logger.logError(err);
            dispatch({
                type: MessageRequestTypeName.SendMessageError,
                payload: err
            })
        }
    };
};