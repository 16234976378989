import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { IApplicationState } from '../store';
import { withRouter } from 'react-router';
import { App } from '../App';

const mapStateToProps = (state: IApplicationState) => ({
    ...state,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
});

export default
    withRouter(connect(
        mapStateToProps,
        mapDispatchToProps)
        (App));