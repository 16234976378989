import axios from 'axios';

const baseHeaders = {
  'Content-Type': 'application/json'
};

export const apiClient = axios.create({
  baseURL: `api/`,
  responseType: 'json',
  headers: baseHeaders
});

apiClient.interceptors.request.use(function (config) {
  return config;
});