import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faCarBattery, faCarCrash, faCircle, faGlasses, faHammer, faMobileAlt, faNotesMedical, faServer, faStop, faStopCircle, faTools, faTruck } from "@fortawesome/free-solid-svg-icons";
import { faFortAwesome } from "@fortawesome/free-brands-svg-icons";

class About extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="bg-light bg-about" id="about">
          <div className="container py-6">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-9 col-lg-6 order-0 order-lg-1 aos-init aos-animate" data-aos="fade-right"></div>
              <div className="col-lg-6 order-1 order-lg-0 mb-5 mb-lg-0 aos-init aos-animate text-white" data-aos="fade-up">
                <div className="mb-5 pt-8">
                  <h1 className="about-title">Comprehensive Services tailored to your needs</h1>
                  <p className="lead pt-1 pb-5">For nearly 20 years, Perth locals have turned to us for all their automotive service needs. We've got you covered.</p>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <h5><FontAwesomeIcon icon={faTools}/> Vehicle Servicing</h5>
                    <p className="mb-2 small">Full automotive car servicing from brakes, to tyres, to engines - we do it all. We also do logbook servicing.</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <h5><FontAwesomeIcon icon={faCarCrash}/> Repair Services</h5>
                    <p className="mb-2 small mb-0">Did a mishap occur? No worries, we're here to help. Insured or not insured, we provide estimates and also repairs.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <h5><FontAwesomeIcon icon={faCarBattery}/> Batteries and Parts</h5>
                    <p className="mb-2 small mb-0">Know what you're doing and just need the part? Explore our wide range of accessories and parts.</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <h5><FontAwesomeIcon icon={faNotesMedical}/> Car Inspections</h5>
                    <p className="small mb-0">Needing an inspection or valuation done? We're certified by the Department of Transport, and car insurance agencies to help.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
