import React from "react";

import "../Home.css"

export const GoogleMaps: React.FC = () => {

    return (
        <React.Fragment>
            <iframe
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Unit%202/35%20Colin%20Jamieson%20Drive,%20Welshpool%20WA,%206106&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight={0}
                marginWidth={0}>
            </iframe>
        </React.Fragment>
    );
}

export default GoogleMaps;