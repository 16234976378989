import React from "react";

import "../Home.css"

class Results extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-dark text-center object-wrap vertical-center" id="results" style={{minHeight: "400px"}}>
          <div className="bg-results object-body object-body-right"></div>
          <div className="container container-fluid">
            <div className="row section-xl text-white">
              <div className="col-lg-6">
                <h2>100% Results <strong>Guarantee</strong></h2>
                <p className="">We stand for expertise, value, and professionalism - and have so since from the day we began. We offer a lifetime guarantee on select services. So rest assured, you're in good hands.</p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Results;
