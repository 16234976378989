import EventEmitter from 'events';

export const eventEmitter: EventEmitter = new EventEmitter();

export class FlashMessageNotifier {

    static createSuccess(message: string, title: string = "", type: string = "success"): void {
        eventEmitter.emit('flash', ({ message, title, type }));
    }

    static createError(message: string, title: string = "Error", type: string = "danger"): void {
        eventEmitter.emit('flash', ({ message, title, type }));
    }
}