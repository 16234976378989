import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { ApplicationUrls } from '../../constants/ApplicationUrls';

import './Footer.css';

const Footer: React.FC<any> = (props: any) => {
    return (
        <React.Fragment>
            <footer className="footer pt-6 pb-5 mt-auto bg-light footer-light">
                <hr className="pb-4" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="footer-brand">
                                Eagle Automotive Repairs
                            </div>
                            <div className="mb-3"><small>your automotive service partner</small></div>
                            <div className="icon-list-social mb-5">
                                <a className="icon-list-social-link" href="https://www.facebook.com/devizorau/">
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                                <a className="icon-list-social-link" href="mailto:enquire@eagleautomotive.com.au" rel="noopener noreferrer" target="_blank">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                                    <div className="text-uppercase-expanded text-xs mb-4"></div>
                                    <ul className="list-unstyled mb-0">
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                                    <div className="text-uppercase-expanded text-xs mb-4"></div>
                                    <ul className="list-unstyled mb-0">
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                                    <div className="text-uppercase-expanded text-xs mb-4">Other Services</div>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-2"><a href="#" target="_blank" rel="noopener noreferrer">Coming Soon</a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="text-uppercase-expanded text-xs mb-4">Legal</div>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-2"><a href={ApplicationUrls.Legal}>Privacy Policy</a></li>
                                        <li className="mb-2"><a href={ApplicationUrls.Legal}>Terms and Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-5" />
                    <div className="row align-items-center">
                        <div className="col-md-6 small">Copyright © Eagle Automotive Repairs {new Date().getFullYear()}. Powered by <a href="https://devizor.io">devizor</a>.</div>
                        <div className="col-md-6 text-md-right small">
                            <a href={ApplicationUrls.Legal}>Privacy Policy</a>
                            <span className="mx-2">·</span>
                            <a href={ApplicationUrls.Legal}>Terms &amp; Conditions</a>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer;