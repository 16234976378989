import * as React from 'react';
import { withRouter } from 'react-router';

/**
 * Add this component to ensure on react router transitions user is taken to top of page
 * https://reactrouter.com/web/guides/scroll-restoration/scroll-to-top
 */
class ScrollToTopOnRouteChange extends React.Component<any, any> {
    componentDidUpdate(prevProps: any) {
        if (
            this.props.location.pathname !== prevProps.location.pathname
        ) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return null;
    }
}

export default withRouter(ScrollToTopOnRouteChange)
