import * as React from 'react';
import NavMenu from './NavigationMenu/NavMenu';
import Footer from './Footer/Footer';
import FlashMessage from './FlashMessage/FlashMessage';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <FlashMessage></FlashMessage>
        <NavMenu />
        <div className="page-content" style={{minHeight: "90vh"}}>
            {props.children}
        </div>
        <Footer />
    </React.Fragment>
);
