import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import Home from './containers/HomeContainer';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import LegalPage from './components/LegalPage/LegalPage';
import ContactUsPage from './containers/ContactUsPageContainer';
import ScrollToTopOnRouteChange from './utils/ScrollToTopOnRouteChange';
import { ApplicationUrls } from './constants/ApplicationUrls';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './assets/css/utils.css'
import './assets/css/forms.css'
import './assets/css/backgrounds.css'
import './assets/css/main.css'

export class App extends React.Component<{}, any> {

    componentDidMount(){
        AOS.init({
            duration : 600
          })
    }

    render() {
        return <React.Fragment>
            <Layout>
                <ScrollToTopOnRouteChange />
                <Switch>
                    <Route exact path={ApplicationUrls.Home} component={Home} />
                    {/* <Route path={ApplicationUrls.ContactUs} component={ContactUsPage} /> */}
                    <Route path={ApplicationUrls.Legal} component={LegalPage} />
                    <Route component={NotFoundPage}></Route>
                </Switch>
            </Layout>
        </React.Fragment>;
    }
}
