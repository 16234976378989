import ReactGA from 'react-ga';

export class AnalyticsUtil {
    static initialiseGoogleAnalytics(): void {
        ReactGA.initialize('UA-214745138-1', {
            debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
            titleCase: false,
            gaOptions: {
                userId: undefined,
            } as any
        });

        ReactGA.pageview(window.location.href);
    }

    static reportPageChanges(history: any): void {
        history.listen((location: any) => ReactGA.pageview(location.pathname));
    }
}
