import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { SmoothScrollButton } from '../../FormCommon/SmoothScroll';

import '../Home.css';


const Hero = () => (
    <React.Fragment>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary">
            <div className="page-header-content py-6">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-0 aos-init aos-animate" data-aos="fade-up">
                        </div>
                        <div className="col-lg-12 d-lg-block aos-init aos-animate" data-aos="fade-up" data-aos-delay="50" style={{height: "40vh"}}>
                            <h1 className="page-header-title">Expert mechanics for your car</h1>
                            <p className="page-header-text mb-5">Your one stop shop for all car maintainence and repair services.</p>
                            <SmoothScrollButton
                                className="btn-primary rounded-0 lift lift-sm"
                                to="value"
                                content={<React.Fragment><span>Find out More</span><FontAwesomeIcon icon={faArrowDown} className="ml-2" /></React.Fragment>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </React.Fragment>
);

export default Hero;
