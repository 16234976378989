export interface MessageRequest {
    title: string;
    message: string;
}

export interface MessageState {
    
}

export enum MessageRequestTypeName{
    SendMessage = "SendMessage",
    SendMessageError = "SendMessageError",
}

interface SendMessageSuccessAction {
    type: MessageRequestTypeName.SendMessage;
    payload: any;
}

interface SendMessageErrorAction {
    type: MessageRequestTypeName.SendMessageError;
    payload: any;
}

export type MessageActionTypes = SendMessageSuccessAction | SendMessageErrorAction
