export class DarkModeUtils {

    public static isDarkModePreferred(): boolean {
        return false;
        const userPreference = localStorage.getItem("useDarkTheme");
        const isDark = userPreference === "true" || (!userPreference && window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches);

        this.setUiToDarkMode(isDark);
        return isDark;
    }

    public static setDarkModePreference(isDark: boolean): boolean {
        if (!window.matchMedia) {
            return false;
        }

        localStorage.setItem("useDarkTheme", isDark ? "true" : "false");
        this.setUiToDarkMode(isDark);
        return true;
    }

    // https://mybyways.com/blog/forcing-dark-mode-with-prefs-color-scheme-media-query
    private static setUiToDarkMode(isDark: boolean): void {
        var styleSheets = document.styleSheets;

        for (var i = styleSheets.length - 1; i >= 0; i--) {
            let styleSheetRules;

            try {
                styleSheetRules = (styleSheets[i] as any).rules;
            }
            catch (e) {
                //Style sheet may not be accessible due to CORS
                continue;
            }

            for (var j: any = styleSheetRules.length - 1; j >= 0; j--) {
                var rule = styleSheetRules[j].media;

                if (rule === undefined || rule.mediaText === undefined) {
                    continue;
                }

                if (rule.mediaText.includes("prefers-color-scheme")) {
                    switch (isDark) {
                        case false:
                            rule.appendMedium("original-prefers-color-scheme");
                            if (rule.mediaText.includes("light")) rule.deleteMedium("(prefers-color-scheme: light)");
                            if (rule.mediaText.includes("dark")) rule.deleteMedium("(prefers-color-scheme: dark)");
                            break;
                        case true:
                            rule.appendMedium("(prefers-color-scheme: light)");
                            rule.appendMedium("(prefers-color-scheme: dark)");
                            if (rule.mediaText.includes("original")) rule.deleteMedium("original-prefers-color-scheme");
                            break;
                        default:
                            rule.appendMedium("(prefers-color-scheme: dark)");
                            if (rule.mediaText.includes("light")) rule.deleteMedium("(prefers-color-scheme: light)");
                            if (rule.mediaText.includes("original")) rule.deleteMedium("original-prefers-color-scheme");
                    }
                    break;
                }
            }
        }
    }
}