import React, { useEffect, useState } from 'react';
import { eventEmitter } from '../../utils/FlashMessageNotifier';

import './FlashMessage.css';
import { Toast, ToastHeader, ToastBody } from 'reactstrap';

const FlashMessage: React.FC<any> = (props: any) => {

    let [visibility, setVisibility] = useState(false);
    let [message, setMessage] = useState('');
    let [title, setTitle] = useState('');
    let [type, setType] = useState('');

    useEffect(() => {
        eventEmitter.addListener('flash', ({ message, title, type }) => {
            setVisibility(true);
            setMessage(message);
            setTitle(title);
            setType(type);
            setTimeout(() => {
                setVisibility(false);
            }, 8000);
        });
    }, []);

    const toggle = () => setVisibility(!visibility);

    return (
        <React.Fragment>
            <div className={`alert`}>
                <Toast isOpen={visibility} className={`${type}-border`}>
                    <ToastHeader toggle={() => toggle()}>
                        {title}
                    </ToastHeader>
                    <ToastBody>
                        {message}
                    </ToastBody>
                </Toast>
            </div>
        </React.Fragment >
    )
}

export default FlashMessage;
