import {
    MessageActionTypes,
    MessageRequestTypeName,
    MessageState,
} from "./types";
import { FlashMessageNotifier } from "../../utils/FlashMessageNotifier";
import { Logger } from "../../utils/Logger";

const initialState: MessageState = {
};

export function messageReducer(
    state = initialState,
    action: MessageActionTypes
) {
    switch (action.type) {
        case MessageRequestTypeName.SendMessage:
            FlashMessageNotifier.createSuccess("Thanks for getting in contact with us. We'll be in touch with you shortly.", "Message sent successfully.");
            return state;
        case MessageRequestTypeName.SendMessageError:
            Logger.logError("Failed to send Message. %O" + action.payload )
            FlashMessageNotifier.createError("Something went wrong. Please try again later.", "Message Not Sent");
            return state;
        default:
            return state;
    }
}
