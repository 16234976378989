import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';

export interface MarkdownTextProps {
    filePath: string;
}

interface MarkdownTextState {
    markdown: string;
}

class MarkdownText extends React.Component<MarkdownTextProps, MarkdownTextState> {
    state = { markdown: "" };

    componentWillMount() {
        fetch(this.props.filePath)
            .then(res => res.text())
            .then(text => this.setState({ markdown: text }));
    }

    render() {
        const { markdown } = this.state;

        return (
            <React.Fragment>
                <ReactMarkdown source={markdown} />
            </React.Fragment>
        );
    }
}

export default MarkdownText;