import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faMobileAlt, faServer, faStar, faStarHalf, faTruck } from "@fortawesome/free-solid-svg-icons";
import { faFortAwesome, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { SmoothScrollButton } from "../../FormCommon/SmoothScroll";

class CallToAction extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="bg-white" id="cta">
                    <div className="container py-5 text-center">
                        <h3 className="cta-title" style={{ color: "var(--primary)" }}>
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStarHalf} />
                        </h3>
                        <h6>
                            388 reviews.
                            <br />
                            4.5 Stars on <FontAwesomeIcon icon={faGoogle} />oogle. <FontAwesomeIcon icon={faCheck} /> 
                        </h6>
                    </div>
                </section>

            </React.Fragment>
        );
    }
}

export default CallToAction;
